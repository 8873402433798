import React from 'react'
import Layout from 'components/global/Layout'
import Metadata from 'components/global/Metadata'
import Menu from 'components/global/Menu'
import Home from 'components/backgrounds/Home'
import NewRecord from 'components/sections/NewRecord'
import BandPhoto from 'components/sections/BandPhoto'
import Footer from 'components/sections/Footer'

const IndexPage = () => (
  <Layout>
    <Metadata title="Home" />
    <Home>
      <Menu />
    </Home>
    <NewRecord />
    <BandPhoto />
    <Footer />
  </Layout>
)

export default IndexPage
