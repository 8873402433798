import React from 'react'
import Box from 'components/elements/Box'
import WaitingDrum from 'components/backgrounds/WaitingDrum'

const Footer = () => (
  <Box
    p="large"
    bg="background.1"
    color="foreground.0"
    fontSize="small"
    textAlign="center"
    display="flex"
    justifyContent="center"
  >
    &copy;2020 Royal Forest
    <br />
    Austin, TX
  </Box>
)

export default Footer
