import React from 'react'
import styled from 'styled-components'
import {
  compose,
  space,
  layout,
  typography,
  color,
  flexbox,
  grid,
  background,
  border,
  position,
  variant,
} from 'styled-system'

const kind = variant({
  prop: 'kind',
  variants: {
    normal: {
      color: 'foreground.0',
      borderColor: 'neutral.3',
      textShadow: 'low',
    },
  },
})

const StyledLink = styled.a`
  ${compose(kind, space, layout, typography, color, flexbox, grid, background, border, position)}
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

const ExternalLink = (props) => <StyledLink target="_blank" {...props} />

ExternalLink.defaultProps = {
  kind: 'normal',
}

export default ExternalLink
