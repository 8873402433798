import React from 'react'
import Box from 'components/elements/Box'
import ExternalLink from 'components/elements/ExternalLink'
import BandLogo from './BandLogo'

const Menu = () => (
  <Box display="flex" justifyContent="flex-end">
    <Box
      display="flex"
      flexDirection="column"
      pt={['xxxlarge', 'gigantic', 'xgigantic']}
      pr={['xlarge', 'gigantic']}
    >
      <Box mb={['xxlarge', 'gigantic']}>
        <BandLogo />
      </Box>
      <Box display="flex" flexDirection="column" alignItems="flex-end">
        <ExternalLink mb="large" href="https://smarturl.it/rf-bandcamp">
          bandcamp
        </ExternalLink>
        <ExternalLink mb="large" href="https://smarturl.it/rf-spotify">
          spotify
        </ExternalLink>
        <ExternalLink mb="large" href="https://smarturl.it/rf-instagram">
          instagram
        </ExternalLink>
        <ExternalLink mb="large" href="https://smarturl.it/rf-youtube">
          youtube
        </ExternalLink>
      </Box>
    </Box>
  </Box>
)

export default Menu
