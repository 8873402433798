import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import BandLogoSvg from 'assets/band-logo.svg'

const BandLogo = styled(BandLogoSvg)`
  width: 250px;
  height: auto;
  opacity: 0.8;
  filter: invert(69%) sepia(54%) saturate(280%) hue-rotate(7deg) brightness(95%) contrast(97%);

  @media (max-width: ${themeGet('breakpoints.medium')}) {
    width: 180px;
  }
`

export default BandLogo
