import styled from 'styled-components'
import { compose, space, typography, color, layout, flexbox } from 'styled-system'

const Text = styled.div(
  compose(
    color,
    flexbox,
    layout,
    space,
    typography,
  ),
)

Text.defaultProps = {
  fontSize: 'medium',
  lineHeight: 'medium',
  color: 'foreground.0',
}

export default Text
