import React from 'react'
import Box from 'components/elements/Box'
import Text from 'components/elements/Text'
import ExternalLink from 'components/elements/ExternalLink'
import WaitingDrum from 'components/backgrounds/WaitingDrum'

const NewRecord = () => (
  <Box p="xxxlarge" bg="background.1" display="flex" justifyContent="center">
    <Box maxWidth="900px" display="flex" flexDirection="column" alignItems="center">
      <Box width="50vw" maxWidth="500px" mb="xxlarge">
        <WaitingDrum />
      </Box>
      <Box display="flex" flexDirection="column" textAlign="center" alignItems="center">
        <Box mb="xxlarge">
          <Text mb="small" fontSize="xlarge" lineHeight="xlarge">
            WAITING DRUM
          </Text>
          <Text>Available Now</Text>
        </Box>
        <Box display="flex" flexDirection="column">
          <ExternalLink mb="small" href="http://rf18.bandcamp.com/album/waiting-drum">
            BANDCAMP
          </ExternalLink>
          <ExternalLink mb="small" href="https://open.spotify.com/album/6A6KWKqWVGI0CsM8AsjWxA?">
            SPOTIFY
          </ExternalLink>
          <ExternalLink
            mb="small"
            href="https://music.apple.com/us/album/waiting-drum/id1519163003"
          >
            APPLE MUSIC
          </ExternalLink>
          <ExternalLink mb="small" href="https://soundcloud.com/royalforest/sets/waiting-drum">
            SOUNDCLOUD
          </ExternalLink>
        </Box>
      </Box>
    </Box>
  </Box>
)

export default NewRecord
