import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import BackgroundImage from 'gatsby-background-image'

function Background(props) {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "bg-home.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 2560) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const imageData = data?.backgroundImage?.childImageSharp?.fluid
  return <BackgroundImage Tag="section" fluid={imageData} backgroundColor={`#040e18`} {...props} />
}

const Home = styled(Background)`
  top: 0;
  width: 100%;
  height: 675px;
  background-position: 80% 30%;
  background-size: cover;

  @media (max-width: ${themeGet('breakpoints.medium')}) {
    height: 450px;
  }

  @media (max-width: ${themeGet('breakpoints.small')}) {
    height: 350px;
  }
`

export default Home
