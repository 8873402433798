import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import Img from 'gatsby-image'

function Image(props) {
  const data = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "bg-band.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1839) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const imageData = data?.backgroundImage?.childImageSharp?.fluid
  return <Img fluid={imageData} />
}

export default Image
